import React, { useContext } from "react"
import { LanguageContext } from "../../context"
import Icon from "../icon"

 const EventsDayHour = (props) => {

   const { datehour } = props

   const { t } = useContext(LanguageContext)
   return (
     <div className="day-hour">
       <div className="day-hour-label label">
            <Icon
                 icon="icons-32-x-32-export-clock"
                 className="icon m-r-12"
                 size={24}
                 color="green"
               />
               DIA I HORA
       </div>
       <div className="day-hour-value m-b-22"> {datehour} </div>
     </div>
   )
 }

 export default EventsDayHour
