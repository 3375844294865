import React, { useContext } from "react"
import { LanguageContext } from "../../context"

 const EventsDateCircle = (props) => {

   const { title, day, month, description, datehour, place } = props

   const { t } = useContext(LanguageContext)
   return (
     <span key={day}>
     <span className="event-date">
       <span className="day"> {day} </span>
       <span className="month"> {month} </span>
     </span>
     <span className="event-beig-circle"></span>
     </span>
   )
 }

 export default EventsDateCircle
