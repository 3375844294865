import React from "react"
import SearchBar from "../search"
import PropTypes from "prop-types"
import { useBreakpoint } from "gatsby-plugin-breakpoints"


const Filters = (props) => {

  const {
    action,
    //searchQuery,,
    //setSearchQuery,
    //button1,
    //button2,
    marginbottom,
    buttons=[],
    wrapperClass,
    hasSearch,
    marginTop,
    searchPlaceholder,
    initialQuery,
    refreshList,
    searchFieldClassName
  } = props

  const breakpoints = useBreakpoint()


  return(
    <div className={`filters hide ${marginbottom} ${marginTop}`}> {/*@TODO: enable search*/}
      <div className="row m-l-r-0">
      <div className={!hasSearch ? "col-12 p-l-0" : "col-6 col-md-4 p-l-0"}>
        {buttons && buttons.map((button, index) => (
            <div key={index} className={`button-wrapper button-wrapper-${index}`}>
              {button}
            </div>
        ))}
      </div>

      {hasSearch &&
        <div className={searchFieldClassName}>
          <SearchBar
              //searchQuery={searchQuery}
              //setSearchQuery={setSearchQuery}
              action={action}
              icon="icons-32-x-32-export-search"
              label={searchPlaceholder}
              placeholder={breakpoints.xs ? "Cerca" : searchPlaceholder}
              buttonText="Search"
              initialQuery={initialQuery}
              refreshList={refreshList}
          />
        </div>
      }

    </div>
  </div>
  )
}

Filters.propTypes = {
  //buttons: PropTypes.arrayOf(PropTypes.shape({})
  searchPlaceholder: PropTypes.string,
  searchFieldClassName: PropTypes.string,
}

Filters.defaultProps = {
  //buttons: []
  searchPlaceholder: "​​Cerca ministeris i oportunitats de servei",
  searchFieldClassName: "col-6 col-md-3 offset-md-5 align-right p-r-0"
}
export default Filters
