import React, { useContext } from "react"
import { LanguageContext } from "../../context"

const SectionDescription = (props) => {
  const {title, showKeepReading} = props
  const { t } = useContext(LanguageContext)
  return(
    <div className="section-description p-b-72 small-p-b-40">
      {title}
      {showKeepReading &&
        <p className="cta m-t-20">{t("Segueix llegint per saber més sobre aquest projecte!")}</p>
      }
    </div>
  )
}

export default SectionDescription
