import React, { useContext } from "react"
import { LanguageContext } from "../../context"
import Icon from "../icon"
import EventsDateCircle from "./events-date-circle"
import EventsDayHour from "./events-day-hour"
import EventsPlace from "./events-place"
import Button from "../button-generic"
import { navigate, Link } from "gatsby"

 const EventsItem = (props) => {

   const {
     title,
     day,
     month,
     description,
     datehour,
     place,
     path,
     className
   } = props

   const { t } = useContext(LanguageContext)
   return (
           <div className={className}>
             <EventsDateCircle day={day} month={month} />
             <div className="event-content">
               <div className="event-title m-b-5"> {title} </div>
               <div className="event-desc m-b-38"> {description} </div>
                <EventsDayHour datehour={datehour} />
                <EventsPlace place={place}  />
                <Link
                  to={path}
                  title={t("Veure més")}
                  className="r-more"
                >
                {t("Veure més")}
                <Icon
                 title={t("Veure més")}
                 icon="icons-32-x-32-generic-plus"
                 size={16}
                 color="#ff0"
                 className="icon"
                 />
                </Link>

             </div>
           </div>
   )
 }

 export default EventsItem
