import React, { useContext } from "react"
import { LanguageContext } from "../context"
import Icon from "./icon"
import Button from "./buttons/button-cta-top-content"
import modalBackgroundImage from "../assets/images/modal/modal-header-image-prayer.jpg"
import PropTypes from "prop-types"

 const HomeTopContent = (props) => {

   const { t } = useContext(LanguageContext)
   const {
      text,
      formId,
      buttonTitle,
      isButton,
      className,
      buttonUrl
    } = props

   return (
      <section className={className}>
        <div className="container top-content">
          <div className="row">
            <div className="col-md-12">
              <div className="call-to-action-desc">
                {text}
              </div>
            </div>
            {buttonTitle && isButton && !buttonUrl &&
              <Button
                title={buttonTitle}
                backgroundimage={modalBackgroundImage}
                formId={formId}
               />
            }
            {buttonTitle && !isButton && !buttonUrl &&
              <div
                className="text-center dark-grey Normal-Bold-C---dark-grey"
               >
                {buttonTitle}
              </div>
            }
            {buttonTitle && buttonUrl &&
              <div
                className="text-center dark-grey Normal-Bold-C---dark-grey"
               >
                <a
                  href={buttonUrl}
                  target="_blank"
                  alt={buttonTitle}
                  className="text-center dark-grey Normal-Bold-C---dark-grey   r-more"
                  >{buttonTitle}</a>
              </div>
            }

          </div>
        </div>
      </section>
   )
 }

 HomeTopContent.propTypes = {
   buttonTitle: PropTypes.string,
   formId: PropTypes.string,
   isButton: PropTypes.bool,
   className: PropTypes.string
 }

 HomeTopContent.defaultProps = {
   buttonTitle: `Visita'ns`,
   formId: `visit_us_goel_webform`,
   isButton: true,
   className: `row home-top-content m-b-152 p-t-110`
 }

 export default HomeTopContent
