// src/components/search.js
import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import Icon from "../icon"
import PropTypes from "prop-types"


const SearchBar = ({
    action,
    icon,
    placeholder,
    label,
    buttonText,
    initialQuery,
    refreshList
  }) => {

  const [searchQuery, setSearchQuery] = useState(initialQuery);

  useEffect(() => {
    setSearchQuery(initialQuery);
  }, [initialQuery]);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await navigate(`${action}/?s=${encodeURI(searchQuery)}`);
    } catch (err) {
      console.error(err);
    }
  };

  const clearInput = (e) => {
    e.preventDefault();
    setSearchQuery("");
    refreshList();
  }


  return(
    <form
        onSubmit={onSubmit}
        //action={action}
        method="get"
        autoComplete="off"
        className="search-form"
    >
        <label htmlFor="header-search">
            <span className="visually-hidden">
              {label}
            </span>
        </label>
        <input
            value={searchQuery}
            onInput={(e) => setSearchQuery(e.target.value)}
            type="text"
            id="header-search"
            placeholder={placeholder}
            name="s"
            className={`${searchQuery ? 'has-text' : ''}`}
        />

            <button type="submit" className={`${icon ? 'has-icon' : ''} ${searchQuery ? 'align-left' : ''}`}>
            {
              icon
              ?
              <Icon
                icon={icon}
                className="search-loupe"
                size={24}
                color="bordeaux"
              />
              :
              buttonText
            }
            </button>

            {
              searchQuery &&
              <button
                className={`clear-input`}
                onClick={(e) => clearInput(e)}
              >
                <Icon
                  icon="icons-32-x-32-export-x"
                  className="clear-search"
                  size={24}
                  color="bordeaux"
                />
              </button>
            }
    </form>
  )
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  buttonText: PropTypes.string
}

SearchBar.defaultProps = {
  placeholder: `Search blog posts`,
  label: `Search blog posts`,
  buttonText: `Search`
}

export default SearchBar
