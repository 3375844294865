import React, { useContext, useState } from "react"
import { LanguageContext } from "../../context"
import Banner from "../banner"
import ProjectItem from "../projects/project-item"
import Button2 from "../button-generic"
//import { useStaticQuery, graphql } from "gatsby"
//import { useBreakpoint } from "gatsby-plugin-breakpoints"
import PageTopContent from "../home-top-content"
import { useFlexSearch } from "react-use-flexsearch"
import Filters from "../search/filters"
import Section from "../section"
import Utils from "../../utils"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import EventItem from "../events/events-item"
import {navigate} from "gatsby"

const EventsPage = (props) => {

  const {
    post,
    bannerHeight="408px",
    events,
    buttonBanner,
    lang,
    services,
    pageContext,
  } = props
  const { t } = useContext(LanguageContext)
  const breakpoints = useBreakpoint()

  const eventsGroupedBy = useEventsGrouped(events)

  const eventsSorted = useEventsSorted(eventsGroupedBy)

  const getMonthName = (dateYear) => {

   const date = new Date(dateYear);
    const monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];
    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];
    return monthName;
  }


  const { search } = typeof window !== 'undefined' ? window.location : '';
  const query = new URLSearchParams(search).get('s')
  const [searchQuery, setSearchQuery] = useState(query || '');

  //const index = localSearchPages?.index
  //const store = localSearchPages?.store


  //const results2 = useFlexSearch(searchQuery, index, store);

  const posts = events; //searchQuery ? unFlattenResults(results2) : ministries;

  const getFullDate = (node) => {
    return `${Utils.getDate(node, 'day')} ${Utils.startWithVowel(Utils.getDate(node, 'month'), lang) && lang ==='ca' ? "d'" : (!Utils.startWithVowel(Utils.getDate(node, 'month'), lang)  || lang == 'es')  ? 'de' : '' } ${Utils.getDate(node, 'month')} ${t('a les')}  ${Utils.getDate(node, 'hour')}h`
  }
  return(
    <div className="page-events">
    <Banner
      post={post}
      height={bannerHeight}
      //buttonBanner={buttonBanner}
      //hasButton={true}
      backgroundColor="bordeaux"
      //anchorButton="/serveix#section-services"
      //anchorButtonText={buttonBanner}
    />


     <section className="main-events background-beig">
     <div className="container p-0">
      <Filters
           //button1={<Button2 className="btn-all-ministries p-0" title={t("Tots els Serveis")} />}
           //button2={<Button2 className="btn-dropdown" type="dropdown" title={t("Per Ministeri")} options={ministries} />}
           buttons={[
           <Button2
            className="btn-all-ministries p-0"
            title={t("Tots els Esdeveniments")}
            wrapperClass="col-2- p-l-0 p-r-0 all-events-wrapper m-r-32"
            />,
           <Button2
            className="btn-dropdown filter-ministry"
            type="dropdown"
            title={t("Per Esdeveniment")}
            options={events}
            wrapperClass="col-2- p-l-0 p-r-0 all-dropdown-wrapper"
            />,
            ]}
           searchQuery={searchQuery}
           setSearchQuery={setSearchQuery}
           action="/serveix"
           marginbottom="m-b-88"
           hasSearch={true}
       />

       <h2 className="H2-Medium-C---green-dark p-t-88 m-b-84 medium-m-b-74 small-m-b-16 medium-p-b-0 small-p-b-0">
         {t("Propers Esdeveniments")}
       </h2>
       <div className="row">
       <div className="events-content mx-auto m-w-1076 p-l-0 p-r-0">
       {eventsSorted && Object.entries(eventsSorted).map(([key, events], index) => {
         return(
           <div key={key} className={`${index % 2 === 0 ? 'style-green' : 'style-bordeaux'} row m-b-76`}>
             <div className={` ${ breakpoints.md ? 'col-md-12' : 'col-md-1' } calendar-month m-r-24`}>
               <div className="c-content"> {t(getMonthName(key))} </div>
             </div>
              {events && events.length > 0 && events.map((node, index) => {
                  return (
                    <EventItem
                       key={index}
                       title={Utils.getShortTitle(node?.title)}
                       description={Utils.getDescription(node)}
                       datehour={
                         `${Utils.getDate(node, 'weekday')},
                          ${getFullDate(node)}`}//"Diumenge, 17 d'abril a les 11:00h"
                       place= <span dangerouslySetInnerHTML={{ __html: Utils.getLocation(node) }} />
                       day={Utils.getDate(node, 'day')}
                       month={Utils.getDate(node, 'shortMonth')}
                       className={` ${ breakpoints.md ? 'col-md-6' : 'col-md-4' } calendar-event m-r-24 ${'item-'+index}`}
                       path={node?.path?.alias}
                    />
                  )
              })
              }
           </div>
         )
       })}
       </div>
      </div>
     </div>
     </section>
    </div>
  )
}










const PageTopContentText = ({lang}) => {
  if(lang === "ca"){
    return(
      <div className="p-t-104">
          <div>“Sigui quin sigui el vostre treball, <span className="fill-backgrond">feu-lo de cor</span>, pel Senyor i no pels homes".
           </div>
           <div>
           </div>
      </div>
    )
  }else{
    return(
      <div>
          <div>“Sigui quin sigui el vostre treball, <span className="fill-backgrond">feu-lo de cor</span>, pel Senyor i no pels homes".
           </div>
      </div>
    )
  }
}



export const unFlattenResults = (results) =>{
  return results.map(post => {
    const { summary, alias, title } = post;
      return { alias, body: { title, summary }
    };
  });
}

export const useEventsGrouped = (events) => {
  const groupedDates = events?.edges?.reduce((acc, {node}) => {
  const date = new Date(node?.field_start_time);
  const month = date?.getMonth();
  const year =  date?.getFullYear();
  const key = `${year}-${month+1}`;
  if (!acc[key]) {
    acc[key] = [];
  }
  acc[key].push(node);
  return acc;
}, {});

return groupedDates;
}

export const useEventsSorted = (groupedDates) => {
  if(!groupedDates){
    return;
  }
const sortedGroupedData = {};
const sortedGroupedDates = Object.keys(groupedDates)
  .sort()
  .forEach(key => {
    sortedGroupedData[key] = groupedDates[key].sort((a, b) => {
      const dateA = new Date(a.field_start_time);
      const dateB = new Date(b.field_start_time);
      return dateA - dateB;
    });

  });
/*Object.entries(groupedDates)
  .sort((a, b) => {
    const [ay, am] = a[0].split("-").map(Number);
    const [by, bm] = b[0].split("-").map(Number);
    if (ay !== by) {
      return ay - by;
    }
    return am - bm;
  })
  .reduce((acc, [key, value]) => {
    acc[key] = value;
    console.log("ACC", acc)
    return acc;
  }, {});*/

  return sortedGroupedData;
}

 export default EventsPage
